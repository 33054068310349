import {Linking} from 'react-native';
import {showAlert} from '../service';

const handlePermissionError = (e, source) => {
  const isPermissionError = e?.toString().includes('User did not grant');
  if (isPermissionError) {
    showAlert(
      'Permission Error',
      `Please grant permission to access your ${source}.`,
      () => console.log('Cancel Pressed'),
      () => Linking.openSettings(),
    );
  }
};

export default handlePermissionError;
