import React, {useState} from 'react';
import {ScrollView, Image, View, useWindowDimensions} from 'react-native';
import Text from '../../../components/Text';
import Button from '../../../components/Button';
import Heading from '../../../components/Heading';
import HStack from '../../../components/HStack';
import Center from '../../../components/Center';
import tailwind from 'tailwind-rn';
import {IMAGE_OPTIONS} from '../../../constants';
import {styles} from '../../../styles';
import {RegisterActionBar, RegisterPrevButton} from '../../../components';
import {SafeAreaView} from 'react-native-safe-area-context';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../redux/reducers';
import {RegistrationData} from '../types';
import {setRegisterData} from '../registerReducer';
import isWeb from '../../../utils/isWeb';
import ScreenWrapper from '../components/ScreenWrapper';
import WebcamCapture from '../components/WebcamCapture';
import * as Sentry from '@sentry/react-native';
import { handlePermissionError } from '../../../utils';

const PhotoCapture = ({navigation}) => {
  const dispatch = useDispatch();
  const {profilePicture: initialProfilePicture} = useSelector(
    (state: RootState) => state?.register,
  );
  const [profilePicture, setProfilePicture] = useState(null);
  const {width} = useWindowDimensions();

  const handleImageSubmit = () => {
    const registrationData: RegistrationData = {
      profilePicture,
    };
    //@ts-ignore
    dispatch(setRegisterData(registrationData));
    navigation.navigate('Email');
  };

  const showGalleryPicker = () => {
    if (isWeb) {
      //@ts-ignore
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/png, image/jpeg';

      input.onchange = e => {
        // @ts-ignore
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = event => {
          const data = event.target.result;
          //@ts-ignore
          setProfilePicture(data.split(',')[1]);
        };
      };

      input.click();
    } else {
      const ImagePicker = require('react-native-image-crop-picker').default;
      ImagePicker.openPicker(IMAGE_OPTIONS).then(image => {
        //@ts-ignore
        setProfilePicture(image.data);
      }).then((e)=> handlePermissionError(e, 'gallery'));
    }
  };

  const showCameraPicker = () => {
    const ImagePicker = require('react-native-image-crop-picker').default;
    ImagePicker.openCamera({...IMAGE_OPTIONS, cropping: true}).then(image => {
      const orientation = image.exif.Orientation;
      let rotationAngle = 0;

      if (orientation != 1) {
        switch (orientation) {
          case '3':
            rotationAngle = 180;
            break;
          case '6':
            rotationAngle = 90;
            break;
          case '8':
            rotationAngle = 270;
            break;
          default:
            break;
        }

        Sentry.captureMessage('rotationAngle' + rotationAngle);

        const ImageResizer =
          require('@bam.tech/react-native-image-resizer').default;

        ImageResizer.createResizedImage(
          `data:image/jpeg;base64,${image.data}`,
          IMAGE_OPTIONS.maxWidth,
          IMAGE_OPTIONS.maxHeight,
          'JPEG',
          80,
          rotationAngle,
        )
          .then(response => {
            const RNFS = require('react-native-fs');
            RNFS.readFile(response.uri, 'base64')
              .then(base64Img => {
                setProfilePicture(base64Img);
              })
              .catch(err => {
                console.log('err', err);
              });
          })
          .catch(err => {
            console.log('err', err);
          });
      } else {
        setProfilePicture(image.data);
      }
    }).catch((e)=> handlePermissionError(e, 'camera'));
  };

  return (
    <ScreenWrapper>
      <SafeAreaView
        style={[
          tailwind('flex-1 m-4 p-4'),
          isWeb && {backgroundColor: '#ffffff', width: width / 3},
        ]}>
        <ScrollView style={tailwind('mx-2 mb-4')}>
          <RegisterActionBar
            step={4}
            hasSkip
            onSkip={() => navigation.navigate('Email')}
          />
          <View style={tailwind('mx-6 mt-4')}>
            <Heading size="lg">Profile Photo</Heading>
            <Text style={tailwind('mt-4 text-lg')}>
              Please load a picture of you.
            </Text>
            <Text style={tailwind('mt-4 text-lg')}>
              This needs to be a real picture of you facial recognition will
              compare it to the drivers license photo and your profile if it
              does not match you profile can be rejected.
            </Text>
            <Center style={tailwind('mt-6')}>
              {profilePicture ? (
                <Image
                  source={{
                    uri: `data:image/jpeg;base64,${profilePicture}`,
                  }}
                  style={styles.profileImage}
                />
              ) : (
                <Image
                  source={require('../../../images/default-user-avatar.png')}
                  style={styles.profileImage}
                  resizeMode="contain"
                />
              )}
            </Center>
            {isWeb ? (
              <WebcamCapture
                onCapture={imgData => setProfilePicture(imgData)}
              />
            ) : (
              <Button
                style={tailwind('flex-1 mt-6')}
                variant="ghost"
                size="lg"
                py="4"
                onPress={() => showCameraPicker()}>
                Take picture
              </Button>
            )}
            <Button
              style={tailwind('flex-1 mt-4')}
              variant="ghost"
              size="lg"
              py="4"
              onPress={() => showGalleryPicker()}>
              {isWeb ? 'Select file' : 'Choose from gallery'}
            </Button>
            <Text
              style={{
                marginTop: 8,
                fontSize: 20,
                fontWeight: 'bold',
              }}>
              This profile will not be approved by management if the photo is
              not provided.
            </Text>
          </View>
        </ScrollView>
        <HStack style={tailwind('mx-2')}>
          <RegisterPrevButton />
          <Button
            onPress={() => handleImageSubmit()}
            style={tailwind('mx-2 flex-1')}
            isDisabled={profilePicture === null}>
            Next
          </Button>
        </HStack>
      </SafeAreaView>
    </ScreenWrapper>
  );
};

export default PhotoCapture;
