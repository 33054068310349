// @ts-nocheck
import {
  Platform,
  Pressable,
  View,
  useWindowDimensions,
} from 'react-native';
import tailwind from 'tailwind-rn';
import React from 'react';
import {} from 'react-native-paper';
import {colorSwatches, styles} from '../styles';
import {useDispatch, useSelector} from 'react-redux';
import Menu from './Menu';
import {SignOut} from '../modules/Auth/reducer';
import Ionicon from 'react-native-vector-icons/Ionicons';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import {RootStore} from '../redux/store';
import IconButton from '../components/IconButton';
import Text from '../components/Text';
import ConfirmationDialog from './ConfirmationDialog';
import {Center, HStack} from 'native-base';

type NavbarProps = {
  navigation: {
    goBack: Function;
  };
  title: string;
  hasBackArrow?: boolean;
  hasSearch?: boolean;
  hasFilter?: boolean;
  onClickFilter?: Function;
  hasAccessCard?: boolean;
  onClickAccessCard?: Function;
  hasAdd?: boolean;
  hasSignOut?: boolean;
  onClickAdd?: Function;
  onClickSearch?: Function;
  showCurrentUnit?: boolean;
  showUnitPicture?: boolean;
  hasMoreMenu?: boolean;
  hasProfile?: boolean;
  openProfile?: Function;
  hasReadAll?: boolean;
  readAll?: Function;
  hasSwitchUnit?: boolean;
  switchUnit?: Function;
  fullScreen?: boolean;
  hasGenerateInviteCode?: boolean;
  onClickGenerateInviteCode?:()=>void;
};

const NavbarWithAction = (props: NavbarProps) => {
  const {
    navigation,
    title,
    hasBackArrow,
    hasSearch,
    hasFilter,
    onClickFilter,
    hasAccessCard,
    onClickAccessCard,
    hasAdd,
    hasSignOut,
    onClickAdd,
    onClickSearch,
    showCurrentUnit,
    showUnitPicture,
    hasMoreMenu,
    hasProfile,
    openProfile,
    hasReadAll,
    readAll,
    hasSwitchUnit,
    switchUnit,
    fullScreen,
    hasGenerateInviteCode,
    onClickGenerateInviteCode,
    hasOthers,
    openOthers
  } = props;
  const defaultUnit = useSelector(
    (state: RootStore) => state?.units?.unitLinks,
  ).filter(link => link?.isDefault === true)[0];
  const pictureUrl = defaultUnit?.profilePicture
    ? `data:image/jpeg;base64,${defaultUnit.profilePicture}`
    : 'http://cigated-v3.herokuapp.com/assets/images/unit.jpeg';
  const dispatch = useDispatch();

  const {width} = useWindowDimensions();

  const [isOpen, setIsOpen] = React.useState(false);
  const onClose = () => setIsOpen(false);

  const [isReadAllOpen, setIsReadAllOpen] = React.useState(false);
  const onCloseReadAll = () => setIsReadAllOpen(false);

  const onYesPressed = () => {
    dispatch(SignOut());
  };

  const onNoPressed = () => {
    console.log('Cancel Pressed');
  };


  if (fullScreen) {
    return (
      <View style={styles.navBar}>
        {hasBackArrow && (
          <IconButton
            icon={Platform.OS === 'ios' ? 'chevron-back' : 'arrow-back-outline'}
            size={24}
            onPress={() => navigation.goBack()}
            iconColor={colorSwatches.white.color}
          />
        )}
        {showCurrentUnit ? (
          <Text numberOfLines={1} style={styles.title}>{`Unit ${
            defaultUnit?.unitNickname
              ? defaultUnit?.unitNickname
              : defaultUnit?.unit?.unitName
          } ${title}`}</Text>
        ) : (
          <Text style={styles.title}>{title}</Text>
        )}
        {hasFilter && (
          <IconButton
            icon={'funnel-outline'}
            size={24}
            style={tailwind('mt-2')}
            onPress={() => onClickFilter()}
            iconColor={colorSwatches.white.color}
          />
        )}
        {hasAccessCard && !!defaultUnit && (
          <IconButton
            icon={({color}) => (
              <MaterialCommunityIcon
                name="badge-account-horizontal-outline"
                color={color}
                size={24}
              />
            )}
            style={tailwind('mt-2')}
            onPress={() => onClickAccessCard()}
            iconColor={colorSwatches.white.color}
          />
        )}
        {hasSearch && (
          <IconButton
            icon="search-outline"
            size={24}
            onPress={() => onClickSearch()}
            iconColor={colorSwatches.white.color}
          />
        )}
        <IconButton
          icon={'add'}
          size={28}
          onPress={() => (hasAdd ? onClickAdd() : null)}
          borderless
          style={{
            opacity: hasAdd ? 1 : 0,
          }}
          iconColor={
            hasAdd ? colorSwatches.white.color : colorSwatches.primary.color
          }
        />
        {hasMoreMenu && (
          <Menu
            // placement="top"
            trigger={triggerProps => {
              return (
                <Pressable {...triggerProps} style={tailwind('pl-2 pr-4')}>
                  <Ionicon
                    name="ellipsis-vertical"
                    size={22}
                    color={colorSwatches.white.color}
                  />
                </Pressable>
              );
            }}>
            {hasProfile && (
              <Menu.Item onPress={() => openProfile()}>Profile</Menu.Item>
            )}
            {hasOthers && (
              <Menu.Item onPress={() => openOthers()}>Others</Menu.Item>
            )}
            {hasReadAll && (
              <Menu.Item onPress={() => setIsReadAllOpen(true)}>
                Mark all alerts as read
              </Menu.Item>
            )}
            {hasGenerateInviteCode && (
              <Menu.Item onPress={onClickGenerateInviteCode}>
                Generate Invite Code
              </Menu.Item>
            )}
            {hasSwitchUnit && (
              <Menu.Item onPress={() => switchUnit()}>Switch unit</Menu.Item>
            )}
            {hasSignOut && (
              <Menu.Item onPress={() => setIsOpen(!isOpen)}>Sign Out</Menu.Item>
            )}
          </Menu>
        )}
        <ConfirmationDialog
          isOpen={isOpen}
          onClose={onClose}
          onNoPressed={onNoPressed}
          onYesPressed={onYesPressed}
          description="Are you sure you wish to logout?"
        />
        <ConfirmationDialog
          isOpen={isReadAllOpen}
          onClose={onCloseReadAll}
          onNoPressed={onNoPressed}
          onYesPressed={readAll}
          description="Are you sure you wish to mark all alerts as read ?"
        />
      </View>
    );
  }

  return (
    <Center
      style={{
        backgroundColor: colorSwatches.primary.color,
      }}>
      <View style={styles.navBar}>
        <HStack
          style={{
            flex: 1,
            alignItems: 'center',
            width: width / 3 + 32,
          }}>
          {hasBackArrow && (
            <IconButton
              icon={
                Platform.OS === 'ios' ? 'chevron-back' : 'arrow-back-outline'
              }
              size={24}
              onPress={() => navigation.goBack()}
              iconColor={colorSwatches.white.color}
            />
          )}
          {showCurrentUnit ? (
            <Text numberOfLines={1} style={styles.title}>{`Unit ${
              defaultUnit?.unitNickname
                ? defaultUnit?.unitNickname
                : defaultUnit?.unit?.unitName
            } ${title}`}</Text>
          ) : (
            <Text style={styles.title}>{title}</Text>
          )}
          {hasFilter && (
            <IconButton
              icon={'funnel-outline'}
              size={24}
              style={tailwind('mt-2')}
              onPress={() => onClickFilter()}
              iconColor={colorSwatches.white.color}
            />
          )}
          {hasAccessCard && (
            <IconButton
              icon={({color}) => (
                <MaterialCommunityIcon
                  name="badge-account-horizontal-outline"
                  color={color}
                  size={24}
                />
              )}
              style={tailwind('mt-2')}
              onPress={() => onClickAccessCard()}
              iconColor={colorSwatches.white.color}
            />
          )}
          {hasSearch && (
            <IconButton
              icon="search-outline"
              size={24}
              onPress={() => onClickSearch()}
              iconColor={colorSwatches.white.color}
            />
          )}
          <IconButton
            icon={'add'}
            size={28}
            onPress={() => (hasAdd ? onClickAdd() : null)}
            borderless
            style={{
              opacity: hasAdd ? 1 : 0,
            }}
            iconColor={
              hasAdd ? colorSwatches.white.color : colorSwatches.primary.color
            }
          />
          {hasMoreMenu && (
            <Menu
              // placement="top"
              trigger={triggerProps => {
                return (
                  <Pressable {...triggerProps} style={tailwind('pl-2 pr-4')}>
                    <Ionicon
                      name="ellipsis-vertical"
                      size={22}
                      color={colorSwatches.white.color}
                    />
                  </Pressable>
                );
              }}>
              {hasProfile && (
                <Menu.Item onPress={() => openProfile()}>Profile</Menu.Item>
              )}
              {hasOthers && (
                <Menu.Item onPress={() => openOthers()}>Others</Menu.Item>
              )}
              {hasReadAll && (
                <Menu.Item onPress={()=> setIsReadAllOpen(true)}>
                  Mark all alerts as read
                </Menu.Item>
              )}
              {hasSwitchUnit && (
                <Menu.Item onPress={() => switchUnit()}>Switch unit</Menu.Item>
              )}
              {hasSignOut && (
                <Menu.Item onPress={() => setIsOpen(!isOpen)}>
                  Sign Out
                </Menu.Item>
              )}
            </Menu>
          )}
        </HStack>
        <ConfirmationDialog
          isOpen={isOpen}
          onClose={onClose}
          onNoPressed={onNoPressed}
          onYesPressed={onYesPressed}
          description="Are you sure you wish to logout?"
        />
        <ConfirmationDialog
          isOpen={isReadAllOpen}
          onClose={onCloseReadAll}
          onNoPressed={onNoPressed}
          onYesPressed={readAll}
          description="Are you sure you wish to mark all alerts as read ?"
        />
      </View>
    </Center>
  );
};

export default NavbarWithAction;
