// @ts-nocheck
import React from 'react';
import {Linking, View} from 'react-native';
import tailwind from 'tailwind-rn';
import {ThemedSwipeablePanel} from '.';
import {styles} from '../styles';
import Text from '../components/Text';
import isWeb from '../utils/isWeb';
import WebcamCapture from '../modules/Auth/components/WebcamCapture';
import Button from './Button';
import {Center} from 'native-base';
import {IMAGE_OPTIONS} from '../constants';
import * as Sentry from '@sentry/react-native';
import {handlePermissionError} from '../utils'

interface I_PhotoCaptureSheet_Props {
  isPanelActive: boolean;
  setIsActive: Function;
  onConfirmImage: Function;
  cropping?:boolean;
}

const PhotoCaptureSheet = ({
  isPanelActive,
  setIsActive,
  onConfirmImage,
  cropping = false
}: I_PhotoCaptureSheet_Props) => {

  const showGalleryPicker = () => {
    if (isWeb) {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/png, image/jpeg';

      input.onchange = e => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = event => {
          const data = event.target.result;
          //@ts-ignore
          onConfirmImage(data.split(',')[1]);
        };
      };
      input.click();
    } else {
      const ImagePicker = require('react-native-image-crop-picker').default;
      ImagePicker.openPicker(IMAGE_OPTIONS).then(image => {
        onConfirmImage(image?.data);
      }).catch((e)=> handlePermissionError(e, 'gallery'));
    }
  };

  const showCameraPicker = () => {
    const ImagePicker = require('react-native-image-crop-picker').default;
    ImagePicker.openCamera({...IMAGE_OPTIONS, cropping}).then(image => {
      const orientation = image.exif.Orientation;
      let rotationAngle = 0;

      if(orientation != 1){
        switch (orientation) {
          case "3":
            rotationAngle = 180;
            break;
          case "6":
            rotationAngle = 90;
            break;
          case "8":
            rotationAngle = 270;
            break;
          default:
            break;
        }

        Sentry.captureMessage("rotationAngle" + rotationAngle);
        
        const ImageResizer =
          require('@bam.tech/react-native-image-resizer').default;

        ImageResizer.createResizedImage(
          `data:image/jpeg;base64,${image.data}`,
          IMAGE_OPTIONS.maxWidth,
          IMAGE_OPTIONS.maxHeight,
          'JPEG',
          80,
          rotationAngle,
        )
          .then(response => {
          const RNFS = require('react-native-fs');
            RNFS.readFile(response.uri, 'base64')
              .then(base64Img => {
                 onConfirmImage(base64Img);
              })
              .catch(err => {
                console.log('err', err);
              });
          })
          .catch(err => {
            console.log('err', err);
          });
      }
      else {
        onConfirmImage(image.data);
      }
    }).catch((e)=>handlePermissionError(e, 'camera'));
  };

  return (
    <ThemedSwipeablePanel
      onlySmall
      fullWidth
      isActive={isPanelActive}
      onClose={() => setIsActive(false)}
      showCloseButton
      closeOnTouchOutside={true}
      closeIconStyle={tailwind('bg-gray-500')}
      closeRootStyle={tailwind('bg-white')}
      noBar
    >
      <View style={tailwind('mt-3 ml-3')}>
        <Text style={styles.sheetTitle}>Select an option</Text>
        <Center style={tailwind('mt-3')}>
          <Button
            variant="ghost"
            size="lg"
            onPress={() => {
              setIsActive(false);
              showGalleryPicker();
            }}>
            {isWeb ? 'Choose file' : 'Pick from Gallery'}
          </Button>
          {isWeb ? (
            <WebcamCapture
              onCapture={imgData => {
                setIsActive(false);
                onConfirmImage(imgData);
              }}
            />
          ) : (
            <Button
              variant="ghost"
              size="lg"
              onPress={() => {
                setIsActive(false);
                showCameraPicker();
              }}>
              Take Picture
            </Button>
          )}
        </Center>
      </View>
    </ThemedSwipeablePanel>
  );
};

export default PhotoCaptureSheet;
