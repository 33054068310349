import soundexConverter from './soundexConverter';
import {deriveDisplayName} from './deriveDisplayName';
import getNotificationAction from './getNotificationAction';
import {buildApprovalMessage} from './buildApprovalMessage';
import {
  formatDate,
  convertDateFrameToDays,
  markCalendarDays,
} from './dateFrames';
import {permissionToBool} from './permissionToBool';
import toLocalDateTime from './toLocalDateTime';
import isDev from './isDev';
import makeId from './makeId';
import getCurrentUnitLink from './getCurrentUnitLink';
import mapPermissionStringToSwitches from './mapPermissionStringToSwitches';
import mapSwitchesToPermissionString from './mapSwitchesToPermissionString';
import isTokenExpired from './isTokenExpired';
import handlePermissionError from './handlePermissionError'

export {
  soundexConverter,
  deriveDisplayName,
  getNotificationAction,
  formatDate,
  convertDateFrameToDays,
  markCalendarDays,
  permissionToBool,
  buildApprovalMessage,
  toLocalDateTime,
  isDev,
  makeId,
  getCurrentUnitLink,
  mapPermissionStringToSwitches,
  mapSwitchesToPermissionString,
  isTokenExpired,
  handlePermissionError,
};
