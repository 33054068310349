import React, {useCallback, useState} from 'react';
import {ScrollView, Image, View, useWindowDimensions} from 'react-native';
import Text from '../../../components/Text';
import Button from '../../../components/Button';
import Heading from '../../../components/Heading';
import HStack from '../../../components/HStack';
import tailwind from 'tailwind-rn';
import {IMAGE_OPTIONS} from '../../../constants';
import {styles} from '../../../styles';
import {RegisterActionBar, RegisterPrevButton} from '../../../components';
import {SafeAreaView} from 'react-native-safe-area-context';
import {useDispatch} from 'react-redux';
import {setRegisterData} from '../registerReducer';
import {residentApi} from '../../../api/resident';
import FeatherIcons from 'react-native-vector-icons/Feather';
import {IDAnalyzer, RegistrationData} from '../types';
import {showErrorMessage} from '../../../service/flashMessage';
import ScreenWrapper from '../components/ScreenWrapper';
import isWeb from '../../../utils/isWeb';
import WebcamCapture from '../components/WebcamCapture';
import { handlePermissionError } from '../../../utils';

const DlCapture = ({navigation}) => {
  const [loading, setLoading] = useState(false);
  const [driversLicenseImage, setDriversLicenseImage] = useState(null);
  const dispatch = useDispatch();

  const {width} = useWindowDimensions();

  const decodeDl = async (dlImage: string) => {
    setLoading(true);
    try {
      const res = await residentApi().post('/idAnalyzer', {
        imageBase64: dlImage,
      });
      const result = res.data;

      if (result.errors) {
        throw new Error(result?.errors[0]);
      } else {
        const dlData: IDAnalyzer = result.data;
        if (dlData) {
          const registrationData: RegistrationData = {
            firstName: dlData?.firstName,
            lastName: dlData?.lastName,
            driversLicenseImage: driversLicenseImage,
            profilePicture: dlData?.croppedface,
            addressLine1: dlData?.address1,
            addressLine2: dlData?.address2,
            dlNumber: dlData?.documentNumber,
            dateOfBirth: dlData?.dob,
            expiration: dlData?.expiry,
            gender: dlData?.sex,
          };
          // @ts-ignore
          dispatch(setRegisterData(registrationData));
          navigation.navigate('Name');
        } else {
          showErrorMessage(
            "Couldn't extract data from the license. Please try again.",
          );
        }
      }
    } catch (error) {
      throw new Error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const showGalleryPicker = () => {
    if (isWeb) {
      //@ts-ignore
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/png, image/jpeg';

      input.onchange = e => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = event => {
          const data = event.target.result;
          //@ts-ignore
          setDriversLicenseImage(data.split(',')[1]);
        };
      };

      input.click();
    } else {
      const ImagePicker = require('react-native-image-crop-picker').default;
      ImagePicker.openPicker(IMAGE_OPTIONS).then(image => {
        //@ts-ignore
        setDriversLicenseImage(image.data);
      }).catch((e)=> handlePermissionError(e, 'gallery'));
    }
  };

  const showCameraPicker = () => {
    const ImagePicker = require('react-native-image-crop-picker').default;
    ImagePicker.openCamera(IMAGE_OPTIONS).then(image => {
      //@ts-ignore
      setDriversLicenseImage(image.data);
    }).catch((e)=> handlePermissionError(e, 'camera'));
  };

  return (
    <ScreenWrapper>
      <SafeAreaView
        style={[
          tailwind('flex-1 m-4'),
          isWeb && {backgroundColor: '#ffffff', width: width / 3},
        ]}>
        <ScrollView style={tailwind('mx-2 mb-4')}>
          <RegisterActionBar
            step={2}
            hasSkip
            onSkip={() => navigation.navigate('Name')}
          />
          <View style={tailwind('mx-6 mt-4')}>
            <Heading size="lg">Driver's License Photo</Heading>
            <Text style={tailwind('mt-8 text-lg')}>
              Please load or take a picture of the front face of your Drivers
              License.From your drivers licence information we will save :
            </Text>
            <HStack style={tailwind('ml-4 mt-4')}>
              <FeatherIcons
                style={tailwind('mt-3 mr-2')}
                name="check-square"
                size={15}
                color="green"
              />
              <Text style={tailwind('mt-2 text-lg')}>Your name .</Text>
            </HStack>
            <HStack style={tailwind('ml-4 mt-2')}>
              <FeatherIcons
                style={tailwind('mt-3 mr-2')}
                name="lock"
                size={15}
                color="green"
              />
              <Text style={tailwind('mt-2 text-lg')}>
                Your drivers licence number (Encrypted) .
              </Text>
            </HStack>
            <HStack style={tailwind('ml-4 mt-2')}>
              <FeatherIcons
                style={tailwind('mt-3 mr-2')}
                name="calendar"
                size={15}
                color="green"
              />
              <Text style={tailwind('mt-2 text-lg')}>
                Month and day of your birthday (no year , we just want to say
                happy birthday to you ) .
              </Text>
            </HStack>
            <HStack style={tailwind('ml-4 mt-2')}>
              <FeatherIcons
                style={tailwind('mt-3 mr-2')}
                name="check-square"
                size={15}
                color="green"
              />
              <Text style={tailwind('mt-2 text-lg')}>
                Drivers license expiration (encrypted, so we know when it needs
                to be renewed) .
              </Text>
            </HStack>
            <Text
              style={{
                marginTop: 8,
                fontSize: 20,
                fontWeight: 'bold',
              }}>
              This profile will not be approved by management if the driver's
              license is not provided.
            </Text>
            <View style={tailwind('mt-8 flex-1')}>
              {driversLicenseImage ? (
                <View>
                  <View style={styles.licenseUploadContainer}>
                    {driversLicenseImage !== null && (
                      <Image
                        source={{
                          uri: `data:image/jpeg;base64,${driversLicenseImage}`,
                        }}
                        style={tailwind('rounded h-64')}
                        resizeMode="contain"
                      />
                    )}
                  </View>
                </View>
              ) : (
                <View style={styles.licenseUploadContainer} />
              )}
            </View>
          </View>
        </ScrollView>
        <HStack style={tailwind('m-2')}>
          {isWeb ? (
            <View style={{flex: 1}}>
              <WebcamCapture
                onCapture={imgData => setDriversLicenseImage(imgData)}
              />
            </View>
          ) : (
            <Button
              style={tailwind('flex-1 mt-6')}
              variant="ghost"
              size="lg"
              py="4"
              onPress={() => showCameraPicker()}>
              Take picture
            </Button>
          )}
          <Button
            style={tailwind('flex-1 mt-4')}
            variant="ghost"
            size="lg"
            py="4"
            onPress={() => showGalleryPicker()}>
            {isWeb ? 'Choose file' : 'Choose from gallery'}
          </Button>
        </HStack>
        <HStack style={tailwind('m-2')}>
          <RegisterPrevButton />
          <Button
            isLoading={loading}
            py="4"
            isLoadingText="Extracting data from DL.Please wait ..."
            onPress={() => decodeDl(driversLicenseImage)}
            style={tailwind('mx-2 flex-1')}
            isDisabled={driversLicenseImage === null}>
            Next
          </Button>
        </HStack>
      </SafeAreaView>
    </ScreenWrapper>
  );
};

export default DlCapture;
